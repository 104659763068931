import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/CardProduct'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import Img from 'gatsby-image'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1
}

const BgImg = styled(Img) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  min-height: 300px;
  max-height: 50vh;
  height: auto;
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`
const DivBgImg2Parent = styled.div`
  width: 300px;
  height: 300px;
  margin: 10px;
  color: white;
  overflow: hidden;
  a {
  text-decoration: none;
  color: inherit; 
  }
`

const BgImg2 = styled(BackgroundImage) `
height: 100%;
width: 100%;
line-height: 300px;
text-align: center;
.span {
display: inline-block;
vertical-align: middle;
line-height: normal;
}
:hover {
    -webkit-transform: scale(1.5,1.5);
    -webkit-transition: all 0.3s ease-in-out;
}
`

const BgImg3 = styled.img `
  width: 100px;
  height: 100px;
  line-height: 300px;
  margin: 10px;
  color: white;
  text-align: center;
  .span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}
`

const FlexContainer = styled.div`
  margin-top: 50px;
  display: flex;
  font: 25px bold;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const FlexContainer2 = styled.div`
  margin-top: 50px;
  border: 1px black;
  border-radius: 10px;
  display: flex;
  font: 25px bold;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`
const TextDiv = styled.div`
  padding-top: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20pt;
  font-weight:  bold;
`

const Slider2 = styled(Slider) `
  background-color: #F8F8F8;
`

const SpanTestimonial = styled.span `
  font-weight: normal;
   font-size: 15px;
`

const Index = ({ data }) => {
  const posts = data.allContentfulKiwimCarousel.edges
  const products = data.allContentfulKiwimProducts.edges
  const testimonials = data.allContentfulKiwimTestimonials.edges
  const featuredPost = posts[0].node
    const icons = [{ "img": "icoColors.jpeg" },
        { "img": "icoFlavors.jpeg" },
        { "img": "icoNatural.jpeg" },
        { "img": "icoPreserve.jpeg" },
        { "img": "icoOil.jpeg" }
    ]
  return (
    <Layout>
      <SEO />
      { (
        <Helmet>
          <title>{`${config.siteTitle}`}</title>
        </Helmet>
          )}
          <div style={{width:"100%"}}>
          <Slider {...settings} className="overflow-hidden">
              {posts.map(({ node: post }) => (
                  <BgImg fluid={post.image.fluid} heigth={'20vh'} />
              ))}
          </Slider>
          <FlexContainer>
              {products.map(({ node: post }) => (
                  <DivBgImg2Parent>
                  <a href={`/product/${post.slug}`}>
                  <BgImg2 fluid={post.heroImage.fluid} backgroundColor={'#eeeeee'} >
                          <span> {post.name} </span>    
                          </BgImg2>
                  </a>
                  </DivBgImg2Parent>
              ))}
          </FlexContainer>
          <TextDiv>
              <span> What you need to know about KIWIM </span>
          </TextDiv>
          <FlexContainer2>
              {icons.map((post) => (
                  <BgImg3 src={withPrefix(post.img)} backgroundColor={'#eeeeee'} >
                  </BgImg3>
              ))}
          </FlexContainer2>
          <Slider2 {...settings} className="overflow-hidden">
              {testimonials.map(({ node: post }) => (
                  <TextDiv>
                      <span>{post.userName}</span>  <br /><br />

                      <SpanTestimonial>{post.testimonial}</SpanTestimonial>
                  </TextDiv>
          ))}
          </Slider2>
      </div>
    </Layout>
  )
}
  /*
      <Container>
        {isFirstPage ? (
          <CardList>
            <Card {...featuredPost} featured />
            {posts.slice(1).map(({ node: post }) => (
              <Card key={post.order} {...post} />
            ))}
          </CardList>
        ) : (
          <CardList>
            {posts.map(({ node: post }) => (
              <Card key={post.order} {...post} />
            ))}
          </CardList>
        )}
      </Container>
          <Pagination context={pageContext} />
      */
export const query = graphql`
  query MyQuery {
  allContentfulKiwimCarousel(limit: 10, sort: {fields: order}) {
    edges {
      node {
        image {
          fluid(maxWidth: 1800,maxHeight: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
  allContentfulKiwimProducts(limit: 10, sort: { fields: updatedAt }) {
    edges {
      node {
        id
        heroImage {
          fluid(maxWidth: 1800, , maxHeight: 800) {
            ...GatsbyContentfulFluid
          }
        }
        name
        slug
      }
    }
  }
  allContentfulKiwimTestimonials(limit: 10, sort: { fields: updatedAt }) {
    edges {
      node {
        userName
        testimonial
      }
    }
  }
}
`


export default Index
